
const api_url = 'https://api-analysis.dinjobbframtid.se/'

export async function get_map(occupation = 'Lager- och terminalpersonal', geo = 'SE Riket') {
    return await (await fetch(api_url + 'map?occupation=' + occupation + '&geo=' + geo)).json()
}

export async function get_similarities(occupation = 'Lager- och terminalpersonal') {
    return await (await fetch(api_url + 'similarities?occupation=' + occupation)).json()
}

export async function get_skills(occupation = 'Lager- och terminalpersonal') {
    return await (await fetch(api_url + 'skills?occupation=' + occupation)).json()
}

export async function get_rate_of_change(occupation = 'Lager- och terminalpersonal') {
    return await (await fetch(api_url + 'rate_of_change?ssyk_label=' + occupation)).json()
}

export async function get_available_occupations() {
    return await (await fetch(api_url + 'occupations')).json()
}

export async function fetchMyAPI() {
    let occs = await get_available_occupations()
    let occupations = []
    for(let occ of occs) {
      occupations.push({ 'value': occ['ssyk_label']})
    }
    //setAvailOccupations(occupations)

    console.log(occs)
    
    return occupations;
  }