import { FunctionComponent } from "react";
import styles from "./ContainerWrapper.module.css";

const ContainerWrapper: FunctionComponent = () => {
  return (
    <div className={styles.instanceParent}>
      <div className={styles.instanceGroup}>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <img className={styles.checkIcon} alt="" src="../check1.svg" />
        </div>
        <div className={styles.loremIpsumParent}>
          <b className={styles.loremIpsum}>Lorem Ipsum</b>
          <div className={styles.loremIpsumDolor}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
            ultricies ultricies scelerisque. Integer nec magna blandit,
            consequat diam nec, vulputate neque.
          </div>
        </div>
      </div>
      <div className={styles.instanceGroup}>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <img className={styles.checkIcon} alt="" src="../check1.svg" />
        </div>
        <div className={styles.loremIpsumParent}>
          <b className={styles.loremIpsum}>Lorem Ipsum</b>
          <div className={styles.loremIpsumDolor}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
            ultricies ultricies scelerisque. Integer nec magna blandit,
            consequat diam nec, vulputate neque.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerWrapper;
