import { FunctionComponent } from "react";
import Container4000 from "../components/Container4000";
import styles from "./TimeDemandContainer.module.css";

type IXEfterfragan = {
  data_rate_of_change : any
};


const TimeDemandContainer = ( props : IXEfterfragan ) => {
  
  const { data_rate_of_change } = props;
  
  return (
    <div className={styles.frameParent}>
      <div className={styles.efterfrganVerTidParent}>
        <b className={styles.efterfrganVerTid}>Efterfrågan över tid</b>
        <div className={styles.hrSerDu}>
          Här ser du hur efterfrågan för sitt yrke ser ut de senaste 10 åren.
        </div>
      </div>
      <Container4000 data_rate_of_change={data_rate_of_change}/>
    </div>
  );
};

export default TimeDemandContainer;
