import { FunctionComponent } from "react";
import styles from "./FutureContainer.module.css";

const FutureContainer: FunctionComponent = () => {
  return (
    <div className={styles.ellipseParent}>
      <img className={styles.groupChild} alt="" src="../ellipse-2.svg" />
      <div className={styles.groupParent}>
        <div className={styles.ellipseGroup}>
          <img className={styles.groupItem} alt="" src="../ellipse-3.svg" />
          <b className={styles.dinFramtid}>Din Framtid</b>
        </div>
        <div className={styles.ellipseContainer}>
          <img className={styles.groupItem} alt="" src="../ellipse-1.svg" />
          <b className={styles.dittYrke}>Ditt Yrke</b>
        </div>
        <div className={styles.groupDiv}>
          <img className={styles.groupItem} alt="" src="../ellipse-5.svg" />
          <b className={styles.dinUtveckling}>
            <p className={styles.din}>Din</p>
            <p className={styles.utveckling}>Utveckling</p>
          </b>
        </div>
        <div className={styles.ellipseParent1}>
          <img className={styles.groupChild1} alt="" src="../ellipse-6.svg" />
          <b className={styles.you}>You</b>
        </div>
      </div>
      <b className={styles.dinJobbframtid}>Din Jobbframtid</b>
    </div>
  );
};

export default FutureContainer;
