import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import JobFramtidContainer from "../components/JobFramtidContainer";
import styles from "./Component5.module.css";

const Component5: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/34");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <img className={styles.child} alt="" src="../group-2202.svg" />
      <JobFramtidContainer
        ellipseImageUrl="../ellipse-182.svg"
        careerDevelopmentText="Ditt yrke"
        careerDevelopmentText2="I det här avsnittet presenterar vi resultatet av den analys vi gjort av ditt yrke. Analysen innehåller tre delar. I den första delen presenterar vi en överblick av ditt yrke där du kan ta del av intressanta fakta av ditt yrke. Därefter presenterar vi en analys av förändringstakten i ditt yrke, det vill säga hur många nya kompetenser som efterfrågas av arbetsgivare jämfört med tidigare. I de två sista delarna har vi analyserat vilka specifika respektive generella kompetenser som efterfrågas. Efter att du har tagit del av det här avsnittet hoppas vi att du ska ha fått en djupare kunskap om ditt yrke och vilka kompetenser som efterfrågas."
        propTop="40.7%"
        propHeight="18.6%"
        propBottom="40.7%"
      />
      <img className={styles.svg1Icon} alt="" src="../svg-12.svg" />
      <img className={styles.item} alt="" src="../ellipse-19.svg" />
      <img className={styles.inner} alt="" src="../ellipse-20.svg" />
      <img className={styles.ellipseIcon} alt="" src="../ellipse-21.svg" />
    </div>
  );
};

export default Component5;
