import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ChangeContainer from "../components/ChangeContainer";
import ContainerKompetenser from "../components/ContainerKompetenser";
import styles from "./ComponentKompGen.module.css";

type ISkills = {
  data_skills : any
};

const ComponentKompGen = ( props : ISkills ) => {

  const { data_skills } = props;

  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/45");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <div className={styles.component76Parent}>
        <ChangeContainer dinFramtidHurDuKanFrverkl="Your skills: Changes" />
        <ContainerKompetenser data_skills={data_skills}
          competencyText="Generella kompetenser"
          pageNumberText="Page 8 of 17"
        />
      </div>
    </div>
  );
};

export default ComponentKompGen;
