import { FunctionComponent } from "react";
import ContainerPercentageSlider from "../components/ContainerPercentageSlider";
import styles from "./GeneralSkillsContainer.module.css";

type IXSkills = {
  data_skills: any
};

const GeneralSkillsContainer = ( props : IXSkills) => {

  const { data_skills } = props;

  let occupation = '';
  let top_specific = ['','','','','','','','','','','',''];
  let top_values = [0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5];

  if(Object.keys(data_skills).length>0) {
    occupation = data_skills[0]['occupation'];
    top_specific = JSON.parse(data_skills[0]['top_general_clean_labels']);
    top_values = JSON.parse(data_skills[0]['top_general_clean_values']);
    top_values = top_values.map(x=> x*400 + 200);
  }
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.generellaKompetenserParent}>
          <b className={styles.generellaKompetenser}>Generella kompetenser</b>
          <div className={styles.loremIpsumDolorContainer}>
            <p className={styles.loremIpsumDolor}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <p className={styles.loremIpsumDolor}>&nbsp;</p>
            <p className={styles.loremIpsumDolor1}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
          <div className={styles.component32Parent}>
            <div className={styles.component32} style={{width: top_values[0]}}>
              <div className={styles.component32Child} />
              <div className={styles.skillName}>{ top_specific[0] }</div>
            </div>
            <div className={styles.component33} style={{width: top_values[1]}}>
              <div className={styles.component33Child} />
              <div className={styles.skillName}>{ top_specific[1] }</div>
            </div>
            <div className={styles.component34} style={{width: top_values[2]}}>
              <div className={styles.component34Child} />
              <div className={styles.skillName}>{ top_specific[2] }</div>
            </div>
            <div className={styles.component35} style={{width: top_values[3]}}>
              <div className={styles.component35Child} />
              <div className={styles.skillName}>{ top_specific[3] }</div>
            </div>
            <div className={styles.component45} style={{width: top_values[4]}}>
              <div className={styles.component45Child} />
              <div className={styles.skillName}>{ top_specific[4] }</div>
            </div>
            <div className={styles.component44} style={{width: top_values[5]}}>
              <div className={styles.component44Child} />
              <div className={styles.skillName}>{ top_specific[5] }</div>
            </div>
            <div className={styles.component43} style={{width: top_values[6]}}>
              <div className={styles.component43Child} />
              <div className={styles.skillName}>{ top_specific[6] }</div>
            </div>
            <div className={styles.component42} style={{width: top_values[7]}}>
              <div className={styles.component42Child} />
              <div className={styles.skillName}>{ top_specific[7] }</div>
            </div>
            <div className={styles.component41} style={{width: top_values[8]}}>
              <div className={styles.component41Child} />
              <div className={styles.skillName}>{ top_specific[8] }</div>
            </div>
            <div className={styles.component46} style={{width: top_values[9]}}>
              <div className={styles.component46Child} />
              <div className={styles.skillName}>{ top_specific[9] }</div>
            </div>
            <div className={styles.component47} style={{width: top_values[10]}}>
              <div className={styles.component47Child} />
              <div className={styles.skillName}>{ top_specific[10] }</div>
            </div>
          </div>
            <ContainerPercentageSlider
              arrowSvgUrl="../arrow-21.svg"
              percentageText="100%"
              percentageValue="50%"
              percentageText2="5%"
              percentageText3="1%"
              propLeft="16.5px"
            />
          </div>
          <div className={styles.malePainterDressedHazmatSuParent}>
            <img
              className={styles.malePainterDressedHazmatSuIcon}
              alt=""
              src="../malepainterdressedhazmatsuitinsidecarshop-11@2x.png"
            />
            <b className={styles.lagerOchTerminalpersonal}>
              { occupation }
            </b>
          </div>
        </div>
      </div>
      <div className={styles.groupParent}>
        <img className={styles.groupIcon} alt="" src="../group1.svg" />
        <div className={styles.page1Of}>Page 5 of 17</div>
      </div>
    </div>
  );
};

export default GeneralSkillsContainer;
