import { FunctionComponent } from "react";
import styles from "./ForecastContainer.module.css";

type IXEfterfragan = {
  data_rate_of_change : any
};

const ForecastContainer = ( props : IXEfterfragan ) => {
  
  const { data_rate_of_change } = props;

  let demand_value = 3; // 1,2, 3

  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.prognosParent}>
          <b className={styles.prognos}>Prognos</b>
          <div
            className={styles.hrSerDu}
          >{`Här ser du de uppskattade efterfrågan inom ditt yrke de kommande 12 månader. `}</div>
        </div>
        <div className={styles.groupParent}>
          <img className={styles.frameChild} alt="" src={"../demand_" + demand_value.toString() + ".svg"} />
          <b className={styles.balance}>Balance</b>
          <b className={styles.lowPossibility}>
            <p className={styles.low}>Low</p>
            <p className={styles.possibility}>possibility</p>
          </b>
          <b className={styles.highPossibility}>
            <p className={styles.low}>High</p>
            <p className={styles.possibility}>possibility</p>
          </b>
        </div>
      </div>
      <div className={styles.groupGroup}>
        <img className={styles.groupIcon} alt="" src="../group1.svg" />
        <div className={styles.page1Of}>Page 6 of 10</div>
      </div>
    </div>
  );
};

export default ForecastContainer;
