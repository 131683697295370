import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./IndustryValidationContainer.module.css";

type IndustryValidationContainerType = {
  courseName?: string;
  courseDescription?: string;

  /** Style props */
  propLeft?: Property.Left;
  propWidth?: Property.Width;
  propTop?: Property.Top;
  propDisplay?: Property.Display;
  propDisplay1?: Property.Display;
  propDisplay2?: Property.Display;
};

const IndustryValidationContainer: FunctionComponent<
  IndustryValidationContainerType
> = ({
  courseName,
  courseDescription,
  propLeft,
  propWidth,
  propTop,
  propDisplay,
  propDisplay1,
  propDisplay2,
}) => {
  const frameDiv1Style: CSS.Properties = useMemo(() => {
    return {
      left: propLeft,
      width: propWidth,
      top: propTop,
    };
  }, [propLeft, propWidth, propTop]);

  const groupIconStyle: CSS.Properties = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  const groupIcon1Style: CSS.Properties = useMemo(() => {
    return {
      display: propDisplay1,
    };
  }, [propDisplay1]);

  const maskGroupIconStyle: CSS.Properties = useMemo(() => {
    return {
      display: propDisplay2,
    };
  }, [propDisplay2]);

  return (
    <div className={styles.frameParent} style={frameDiv1Style}>
      <div className={styles.dittYrkeParent}>
        <b className={styles.dittYrke}>{courseName}</b>
        <div className={styles.component90frame299}>
          <img
            className={styles.component90frame299Child}
            alt=""
            src="../group-2921@2x.png"
            style={groupIconStyle}
          />
          <img
            className={styles.component90frame299Item}
            alt=""
            src="../group-2911@2x.png"
            style={groupIcon1Style}
          />
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="../mask-group@2x.png"
            style={maskGroupIconStyle}
          />
          <img
            className={styles.component90frame299Inner}
            alt=""
            src="../group-293@2x.png"
          />
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.frameChild} />
        <div className={styles.loremIpsumDolor}>{courseDescription}</div>
      </div>
    </div>
  );
};

export default IndustryValidationContainer;
