import { FunctionComponent } from "react";
import JobChangeRateContainer from "../components/JobChangeRateContainer";
import styles from "./CareerReflectionContainer.module.css";

const CareerReflectionContainer: FunctionComponent = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.enReflektionAvDeInsikternaParent}>
        <b className={styles.enReflektionAv}>
          En reflektion av de insikterna du har fått gällande ditt nuvarande
          yrke.
        </b>
        <div className={styles.loremIpsumDolor}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.overviewParent}>
          <b className={styles.overview}>Overview</b>
          <img className={styles.mapaIcon} alt="" src="../mapa.svg" />
        </div>
        <div className={styles.efterfrganVerTidParent}>
          <b className={styles.efterfrganVerTid}>Efterfrägan över tid</b>
          <div className={styles.component80Parent}>
            <div className={styles.component80}>
              <div className={styles.frameContainer}>
                <div className={styles.parent}>
                  <div className={styles.div}>4k</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.parent}>
                  <div className={styles.div}>3k</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.parent}>
                  <div className={styles.div}>2k</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.parent}>
                  <div className={styles.div}>1k</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.parent}>
                  <div className={styles.div}>0</div>
                  <div className={styles.frameChild} />
                </div>
              </div>
              <div className={styles.component80Inner}>
                <div className={styles.rectangleDiv} />
              </div>
            </div>
            <div className={styles.component85}>
              <div className={styles.component85Inner}>
                <div className={styles.frameChild2} />
              </div>
              <div className={styles.div}>2017</div>
            </div>
            <div className={styles.component86}>
              <div className={styles.component85Inner}>
                <div className={styles.frameChild2} />
              </div>
              <div className={styles.div}>2018</div>
            </div>
            <div className={styles.component85}>
              <div className={styles.component85Inner}>
                <div className={styles.frameChild2} />
              </div>
              <div className={styles.div}>2019</div>
            </div>
            <div className={styles.component88}>
              <div className={styles.component85Inner}>
                <div className={styles.frameChild2} />
              </div>
              <div className={styles.div}>2020</div>
            </div>
            <div className={styles.component89}>
              <div className={styles.component85Inner}>
                <div className={styles.frameChild2} />
              </div>
              <div className={styles.div}>2021</div>
            </div>
          </div>
        </div>
        <JobChangeRateContainer
          frndringstaktYrke="Förändringstakt yrke"
          frame324="../frame-324.svg"
          propJustifyContent="center"
          propFlexDirection="row"
        />
        <JobChangeRateContainer
          frndringstaktYrke="Forecast"
          frame324="../frame-290.svg"
          propJustifyContent="flex-start"
          propFlexDirection="column"
        />
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.yourSkillsParent}>
          <b className={styles.overview}>Your Skills</b>
          <div className={styles.specifikaParent}>
            <div className={styles.specifika}>Specifika</div>
            <div className={styles.frameWrapper}>
              <div className={styles.frameParent2}>
                <div className={styles.component32Parent}>
                  <div className={styles.component32}>
                    <div className={styles.component32Child} />
                    <div className={styles.skillName}>Skill name</div>
                  </div>
                  <div className={styles.component33}>
                    <div className={styles.component33Child} />
                    <div className={styles.skillName}>Skill name</div>
                  </div>
                  <div className={styles.component34}>
                    <div className={styles.component34Child} />
                    <div className={styles.skillName}>Skill name</div>
                  </div>
                </div>
                <div className={styles.vectorParent}>
                  <img
                    className={styles.groupChild}
                    alt=""
                    src="../arrow-22.svg"
                  />
                  <div className={styles.parent2}>
                    <div className={styles.div}>1%</div>
                    <div className={styles.div}>5%</div>
                    <div className={styles.div}>10%</div>
                    <div className={styles.div}>50%</div>
                    <div className={styles.div}>100%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.generellaParent}>
            <div className={styles.specifika}>Generella</div>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameParent3}>
                <div className={styles.component32Group}>
                  <div className={styles.component32}>
                    <div className={styles.component32Item} />
                    <div className={styles.skillName3}>Skill name</div>
                  </div>
                  <div className={styles.component331}>
                    <div className={styles.component33Item} />
                    <div className={styles.skillName3}>Skill name</div>
                  </div>
                  <div className={styles.component341}>
                    <div className={styles.component34Item} />
                    <div className={styles.skillName5}>Skill name</div>
                  </div>
                </div>
                <div className={styles.vectorParent}>
                  <img
                    className={styles.groupItem}
                    alt=""
                    src="../arrow-23.svg"
                  />
                  <div className={styles.parent2}>
                    <div className={styles.div15}>1%</div>
                    <div className={styles.div15}>5%</div>
                    <div className={styles.div15}>10%</div>
                    <div className={styles.div15}>50%</div>
                    <div className={styles.div15}>100%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.skillChangesParent}>
          <b className={styles.overview}>Skill Changes</b>
          <div className={styles.frameParent4}>
            <div className={styles.specifikaGroup}>
              <div className={styles.div}>Specifika</div>
              <div className={styles.frameParent5}>
                <div className={styles.kompetensParent}>
                  <b className={styles.kompetens}>Kompetens</b>
                  <div className={styles.efterfrganParent}>
                    <b className={styles.efterfrgan}>Efterfrägan</b>
                    <div className={styles.div20}>2012 - 2021</div>
                  </div>
                  <b className={styles.kompetens}>Trend</b>
                </div>
                <div className={styles.kompetensGroup}>
                  <b className={styles.kompetens}>Kompetens</b>
                  <div className={styles.efterfrganWrapper}>
                    <b className={styles.efterfrgan}>Efterfrägan</b>
                  </div>
                  <b className={styles.kompetens}>Trend</b>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.generellaGroup}>
              <div className={styles.div}>Generella</div>
              <div className={styles.frameParent6}>
                <div className={styles.kompetensContainer}>
                  <b className={styles.kompetens}>Kompetens</b>
                  <div className={styles.efterfrganWrapper}>
                    <b className={styles.efterfrgan}>Efterfrägan</b>
                  </div>
                  <b className={styles.kompetens}>Trend</b>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component51}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.na}>Na%</div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.frameChild7} />
                    <div className={styles.rectangleWrapper}>
                      <div className={styles.frameChild8} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
                <div className={styles.component511}>
                  <div className={styles.loremIpsum}>Lorem Ipsum</div>
                  <div className={styles.naWrapper}>
                    <div className={styles.loremIpsum}>Na%</div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <div className={styles.frameChild9} />
                    <div className={styles.rectangleContainer}>
                      <div className={styles.frameChild10} />
                    </div>
                  </div>
                  <div className={styles.component78Wrapper}>
                    <img
                      className={styles.component78Icon}
                      alt=""
                      src="../component-7838.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerReflectionContainer;
