import { FunctionComponent } from "react";
import ContainerWrapper from "../components/ContainerWrapper";
import styles from "./Component6.module.css";

const Component6: FunctionComponent = () => {
  return (
    <div className={styles.div}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group4.svg" />
      </div>
      <div className={styles.inner}>
        <div className={styles.component76Parent}>
          <div className={styles.component76}>
            <div className={styles.frameParent}>
              <div className={styles.frameChild} />
              <b className={styles.dinFramtid}>
                Din JobbFramtid: ditt nästa steg
              </b>
            </div>
            <div className={styles.viHarNuKommitTillSlutetAWrapper}>
              <b className={styles.viHarNuContainer}>
                <p className={styles.nuDuKan}>
                  Nu du kan förverkliga din jobbframtid!
                </p>
                <p className={styles.viHarNu}>
                  Vi har nu kommit till slutet av analysrapporten där vi pratat
                  om ditt yrke, olika utvecklingsmöjligheter för din jobbframtid
                  och hur du kan stärka din ställning på arbetsmarknaden. Vi
                  hoppas att vi har bidragit till kunskaper kring dina
                  utvecklingsmöjligheter och hur du kan utveckla din kompetens.
                  På den här sidan beskriver vi hur du kan ta dina tankar och gå
                  till handling för att förverkliga din jobbframtid. Vi vill
                  understryka att det här är till för alla oavsett vilka
                  tidigare studier man har bakom sig eller vilka erfarenheter
                  man har av skolan sedan tidigare. Vi vill hjälpa alla att
                  utvecklas för att stå sig starka på arbetsmarknaden genom att
                  utveckla den kompetens som efterfrågas på sikt. I ditt
                  nuvarande yrke eller i ett nytt sådant. Kom ihåg att det inte
                  är för sent även om du hunnit komma långt i yrkeslivet! Stödet
                  som du kan ta del av kan många få mellan det att man är 27 år
                  till dess att man är 62 år om man uppfyller vissa villkor. På
                  denna sida kan du läsa om villkoren och om hur du går till
                  väga för att ansöka om ett vägledningssamtal med en karriär-
                  och studievägledare hos Trygghetsfonden TSL.
                </p>
              </b>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.yourNextStepParent}>
                <b className={styles.yourNextStep}>Your Next Step</b>
                <div className={styles.loremIpsumDolor}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  ultricies ultricies scelerisque. Integer nec magna blandit,
                  consequat diam nec, vulputate neque.
                </div>
              </div>
              <div className={styles.frameDiv}>
                <ContainerWrapper />
                <ContainerWrapper />
                <ContainerWrapper />
                <ContainerWrapper />
              </div>
              <div className={styles.image6Parent}>
                <img
                  className={styles.image6Icon}
                  alt=""
                  src="../image-6@2x.png"
                />
                <b className={styles.toBookAContainer}>
                  <span className={styles.toBookA}>
                    To book a free meeting with a consultant to check your
                    qualification, please scan the QR Code or access our
                    website:
                  </span>
                  <span>{` `}</span>
                  <span>
                    <span className={styles.tslsedinJobbframtidContact}>
                      tsl.se/din-jobbframtid-contact
                    </span>
                  </span>
                </b>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.groupIcon} alt="" src="../group1.svg" />
              <div className={styles.dinJobbframtidAnalysis}>Page 17 of 17</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component6;
