import { FunctionComponent } from "react";
import styles from "./Container4000.module.css";

type IXEfterfragan = {
  data_rate_of_change : any
};

const Container4000 = ( props : IXEfterfragan ) => {

  const { data_rate_of_change } = props;

  let years = [];
  let demands: { [ key : number ]: number } = {};

  for(let i=2013;i<2023;++i) {
    years.push(i);
    demands[i] = 40;
  }

  let af_bristvarde = { '2022': 0, '2026': 0 };
  let occupation = '';
  let rate_of_change = 0;
  let topVal_rounded = 4000;
  let topVal_rounded_3_of_4 = 4000;
  let topVal_rounded_2_of_4 = 4000;
  let topVal_rounded_1_of_4 = 4000;
  let heightFactor = 230.0;

  if(Object.keys(data_rate_of_change).length > 0) {
    rate_of_change = data_rate_of_change[0]['rate of change']
    let years = [2013,2014,2015,2016,2017,2018,2019,2020,2021,2022]
    let vals = [];
    for(let i=0;i<years.length;i++)
      vals.push(data_rate_of_change[0][years[i]])
//    let vals = [ data_rate_of_change[0][2018], data_rate_of_change[0][2019], data_rate_of_change[0][2020], data_rate_of_change[0][2021], data_rate_of_change[0][2022]];

    let topVal = Math.max(...vals);
    let round_factor = 100;
    if(topVal<1000)
      round_factor = 10;
    if(topVal<100)
      round_factor = 1;

    topVal_rounded = Math.round(topVal/(round_factor*10)) * round_factor*10;
    topVal_rounded_3_of_4 = Math.round((topVal*3.0/4)/round_factor) * round_factor;
    topVal_rounded_2_of_4 = Math.round((topVal*2.0/4)/round_factor) * round_factor;
    topVal_rounded_1_of_4 = Math.round((topVal*1.0/4)/round_factor) * round_factor;

    vals = vals.map(x=>heightFactor * x / topVal)
    
    for(let i=0;i<years.length;i++)
      demands[years[i]] = Math.max(40, vals[i]) // temporary fix for 2017 (!)

    af_bristvarde = JSON.parse(data_rate_of_change[0]['af_bristvarde'])
    occupation = data_rate_of_change[0]['ssyk_label']
  }

  return (
    <div className={styles.component80Parent}>
      <div className={styles.component80}>
        <div className={styles.frameParent}>
          <div className={styles.parent}>
            <div className={styles.div}>{ topVal_rounded }</div>
            <div className={styles.frameChild} />
          </div>
          <div className={styles.parent}>
            <div className={styles.div}>{ topVal_rounded_3_of_4 }</div>
            <div className={styles.frameChild} />
          </div>
          <div className={styles.parent}>
            <div className={styles.div}>{ topVal_rounded_2_of_4 }</div>
            <div className={styles.frameChild} />
          </div>
          <div className={styles.parent}>
            <div className={styles.div}>{ topVal_rounded_1_of_4 }</div>
            <div className={styles.frameChild} />
          </div>
          <div className={styles.parent}>
            <div className={styles.div}>0</div>
            <div className={styles.frameChild} />
          </div>
        </div>
        <div className={styles.component80Inner}>
          <div className={styles.rectangleDiv} />
        </div>
      </div>
      <div className={styles.component801} style={ { height: demands[2013] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2013</div>
      </div>
      <div className={styles.component81} style={ { height: demands[2014] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2014</div>
      </div>
      <div className={styles.component82} style={ { height: demands[2015] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2015</div>
      </div>
      <div className={styles.component83} style={ { height: demands[2016] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2016</div>
      </div>
      <div className={styles.component84} style={ { height: demands[2017] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2017</div>
      </div>
      <div className={styles.component85} style={ { height: demands[2018] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2018</div>
      </div>
      <div className={styles.component86} style={ { height: demands[2019] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2019</div>
      </div>
      <div className={styles.component85} style={ { height: demands[2020] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2020</div>
      </div>
      <div className={styles.component88} style={ { height: demands[2021] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2021</div>
      </div>
      <div className={styles.component801} style={ { height: demands[2022] }}>
        <div className={styles.component80Child}>
          <div className={styles.frameChild2} />
        </div>
        <div className={styles.div}>2022</div>
      </div>
    </div>
  );
};

export default Container4000;
