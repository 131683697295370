import { FunctionComponent } from "react";
import styles from "./WorkSkillsContainer.module.css";

const WorkSkillsContainer: FunctionComponent = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.frameChild} />
        <b className={styles.dinFramtid}>Ditt yrke: en överblick</b>
      </div>
      <div className={styles.viHarNuKommitTillSlutetAWrapper}>
        <b className={styles.viHarNu}>
          För att ha en stark ställning på arbetsmarknaden behöver man följa
          utvecklingen av hur ett yrke förändras och vilka kunskaper och
          förmågor som efterfrågas. Här har vi analyserat hur förändringstakten
          ser ut inom ditt yrke. Om förändringstakten är hög innebär det att det
          efterfrågas flera nya kunskaper som inte efterfrågades tidigare. Om
          förändringstakten är låg betyder det att de kompetenser som
          arbetsgivare efterfrågar inte har förändrats i hög omfattning. När du
          ser analysen av förändringstakten i ditt yrke vilka tankar får du då?
        </b>
      </div>
    </div>
  );
};

export default WorkSkillsContainer;
