import { FunctionComponent } from "react";
import ChangeContainer from "../components/ChangeContainer";
import styles from "./WorkContainer.module.css";

const WorkContainer: FunctionComponent = () => {
  return (
    <div className={styles.instanceParent}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <ChangeContainer dinFramtidHurDuKanFrverkl="Your work: The times are changing" />
    </div>
  );
};

export default WorkContainer;
