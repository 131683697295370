import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OccupationContainer from "../components/OccupationContainer";
import styles from "./Component.module.css";

const Component: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/48");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <div className={styles.component76}>
        <div className={styles.frameParent}>
          <div className={styles.frameChild} />
          <b className={styles.dinFramtid}>
            Din Utveckling: i relaterade yrken
          </b>
        </div>
        <div className={styles.viHarNuKommitTillSlutetAWrapper}>
          <div className={styles.viHarNu}>
            I denna del vill vi visa dig några exempel på andra yrken som kräver
            många av de kompetenser som du redan har och som efterfrågas av
            arbetsgivare i ditt yrke. Det här kan vara aktuellt om du funderar
            på att bredda din kompetens för att kunna arbeta i ett annat eller
            kanske flera yrken. De exempel som visas på den här sidan är bara
            några exempel på yrken där du kan bygga vidare på många av de
            kompetenser som efterfrågas i ditt yrke. Vill du utforska dina
            fortsatta möjligheter kan du i den sista delen av denna rapport läsa
            mer om vilka möjligheter du har för att få hjälp med att prata
            igenom dina tankar och eventuella utbildningsinsatser med
            Trygghetsfonden TSL:s karriär- och studievägledare.
          </div>
        </div>
      </div>
      <div className={styles.component69}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameItem} />
          <b className={styles.yourJob}>Low demand</b>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.frameInner} />
          <b className={styles.yourJob}>Medium demand</b>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.rectangleDiv} />
          <b className={styles.yourJob}>High demand</b>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.frameChild1} />
          <b className={styles.yourJob}>Related Job 3</b>
        </div>
      </div>
      <div className={styles.frameGroup}>
        <OccupationContainer />
        <div className={styles.groupParent}>
          <img className={styles.groupIcon} alt="" src="../group1.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 10 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component;
