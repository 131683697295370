import { FunctionComponent } from "react";
import styles from "./ContainerKompetenser.module.css";

type ContainerKompetenserType = {
  competencyText?: string;
  pageNumberText?: string;
  data_skills: any;
};

const ContainerKompetenser: FunctionComponent<
  ContainerKompetenserType
> = ({ competencyText, pageNumberText, data_skills }) => {

  let occupation = '';
  let nr_items = 20;
  let top_labels = new Array(nr_items).fill('');
  let top_values = new Array(nr_items).fill(0.5);
  let top_trend = new Array(nr_items).fill(1);
  let top_forandring = new Array(nr_items).fill(100);

  if(Object.keys(data_skills).length>0) {
      occupation = data_skills[0]['occupation'];
      let type = 'general'
      if(!competencyText?.includes('Generella'))
        type='specific'
      
      top_labels = JSON.parse(data_skills[0]['top_'+ type + '_clean_labels']);
      top_values = JSON.parse(data_skills[0]['top_' + type + '_clean_values']).map((x: number)=> Math.round(x * 100));
      top_trend = JSON.parse(data_skills[0]['top_' + type + '_trend']);

      top_trend = top_trend.map((x:any) => { 
        if(x==3) 
          return "../component-781.svg";
        else if(x==2)
          return "../component-786.svg";
        else
          return "../component-7813.svg";
      })

      //top_values = top_values.map(x=> x*400 + 200);
  }
  

  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.generellaKompetenserParent}>
          <b className={styles.generellaKompetenser}>{competencyText}</b>
          <div className={styles.component69}>
            <div className={styles.rectangleParent}>
              <div className={styles.frameChild} />
              <b className={styles.yourJob}>Lorem Ipsum</b>
            </div>
            <div className={styles.rectangleParent}>
              <div className={styles.frameItem} />
              <b className={styles.yourJob}>Lorem Ipsum</b>
            </div>
            <div className={styles.rectangleParent}>
              <div className={styles.frameInner} />
              <b className={styles.yourJob}>Lorem Ipsum</b>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameItem} />
              <b className={styles.yourJob}>Related Job 3</b>
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.kompetensParent}>
            <b className={styles.kompetens}>Kompetens</b>
            <div className={styles.efterfrganParent}>
              <b className={styles.efterfrgan}>Efterfrägan</b>
              <b className={styles.b}>2012 - 2021</b>
            </div>
            <div className={styles.efterfrganParent}>
              <b className={styles.frandring}>Förandring</b>
              <div className={styles.parent}>
                <b className={styles.yourJob}>0</b>
                <b className={styles.yourJob}>20%</b>
                <b className={styles.yourJob}>40%</b>
              </div>
            </div>
            <b className={styles.kompetens}>Trend</b>
          </div>
          <div className={styles.component51Parent}>
            <div className={styles.component51}>
              <b className={styles.loremIpsum}>{top_labels[0]}</b>
              <div className={styles.na}>{top_values[0]}%</div>
              <div className={styles.rectangleParent1}>
                <div className={styles.frameChild1} />
                <div className={styles.rectangleWrapper}>
                  <div className={styles.frameChild2} style={{width: top_forandring[0]}}/>
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend1"
                  src={top_trend[0]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[1]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[1]}%</div>
              </div>
              <div className={styles.rectangleParent2}>
                <div className={styles.frameChild3}/>
                <div className={styles.rectangleFrame}>
                  <div className={styles.frameChild4} style={{width: top_forandring[1]}} />
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend2"
                  src={top_trend[1]}
                />
              </div>
            </div>
            <div className={styles.component51}>
              <b className={styles.loremIpsum}>{top_labels[2]}</b>
              <div className={styles.na}>{top_values[2]}%</div>
              <div className={styles.rectangleParent1}>
                <div className={styles.frameChild1} />
                <div className={styles.rectangleWrapper}>
                  <div className={styles.frameChild2} style={{width: top_forandring[2]}}/>
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend3"
                  src={top_trend[2]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[3]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[3]}%</div>
              </div>
              <div className={styles.rectangleParent2}>
                <div className={styles.frameChild3} />
                <div className={styles.rectangleFrame}>
                  <div className={styles.frameChild4} style={{width: top_forandring[3]}}/>
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend4"
                  src={top_trend[3]}
                />
              </div>
            </div>
            <div className={styles.component51}>
              <b className={styles.loremIpsum}>{top_labels[4]}</b>
              <div className={styles.na}>{top_values[4]}%</div>
              <div className={styles.rectangleParent1}>
                <div className={styles.frameChild1} />
                <div className={styles.rectangleWrapper}>
                  <div className={styles.frameChild2} style={{width: top_forandring[4]}}/>
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend5"
                  src={top_trend[4]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[5]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[5]}%</div>
              </div>
              <div className={styles.rectangleParent2}>
                <div className={styles.frameChild3} />
                <div className={styles.rectangleFrame}>
                  <div className={styles.frameChild4} style={{width: top_forandring[5]}}/>
                </div>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend6"
                  src={top_trend[5]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[6]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[6]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper}>
                  <div className={styles.groupDiv} style={{width: top_forandring[6]}}>
                    <div className={styles.groupChild} />
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend7"
                  src={top_trend[6]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[7]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[7]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper} >
                  <div className={styles.rectangleWrapper5} style={{width: top_forandring[7]}}>
                    <div className={styles.groupChild} />
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend8"
                  src={top_trend[7]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[8]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[8]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper}>
                  <div className={styles.groupDiv}>
                    <div className={styles.groupChild} style={{width: top_forandring[8]}}/>
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend9"
                  src={top_trend[8]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[9]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[9]}%</div>
              </div>
              <div className={styles.frameParent1} >
                <div className={styles.groupWrapper}>
                  <div className={styles.rectangleWrapper5} style={{width: top_forandring[9]}}>
                    <div className={styles.groupChild} />
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper} >
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend10"
                  src={top_trend[9]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[10]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[10]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper}>
                  <div className={styles.groupDiv} style={{width: top_forandring[10]}}>
                    <div className={styles.groupChild} />
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend11"
                  src={top_trend[10]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[11]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[11]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper}>
                  <div className={styles.rectangleWrapper5} style={{width: top_forandring[11]}}>
                    <div className={styles.groupChild} />
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend12"
                  src={top_trend[11]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[12]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[12]}%</div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.groupWrapper}>
                  <div className={styles.groupDiv}>
                    <div className={styles.groupChild} style={{width: top_forandring[12]}}/>
                  </div>
                </div>
                <div className={styles.frameChild13} />
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend13"
                  src={top_trend[12]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[13]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[13]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild21} style={{width: top_forandring[13]}}/>
              </div>
              <div className={styles.component78Wrapper} >
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend14"
                  src={top_trend[13]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[14]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[14]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild23} style={{width: top_forandring[14]}}/>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend15"
                  src={top_trend[14]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[15]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[15]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild21} style={{width: top_forandring[15]}}/>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend16"
                  src={top_trend[15]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[16]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[16]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild23} style={{width: top_forandring[16]}}/>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend17"
                  src={top_trend[16]}
                />
              </div>
            </div>
            <div className={styles.component511}>
              <b className={styles.loremIpsum}>{top_labels[17]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[17]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild21} style={{width: top_forandring[17]}}/>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend18"
                  src={top_trend[17]}
                />
              </div>
            </div>
            <div className={styles.component516}>
              <b className={styles.loremIpsum}>{top_labels[18]}</b>
              <div className={styles.naWrapper}>
                <div className={styles.na1}>{top_values[18]}%</div>
              </div>
              <div className={styles.rectangleParent7}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild23} style={{width: top_forandring[18]}}/>
              </div>
              <div className={styles.component78Wrapper}>
                <img
                  className={styles.component78Icon}
                  alt=""
                  id="trend19"
                  src={top_trend[18]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.groupParent}>
        <img className={styles.groupIcon} alt="" src="../group1.svg" />
        <div className={styles.page1Of}>{pageNumberText}</div>
      </div>
    </div>
  );
};

export default ContainerKompetenser;
