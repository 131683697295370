import { FunctionComponent } from "react";
import styles from "./JobAnalysisContainer.module.css";

type JobAnalysisContainerType = {
  ellipse1?: string;
  dittYrke?: string;
  loremIpsumDolorSitAmetCon?: string;
};

const JobAnalysisContainer: FunctionComponent<JobAnalysisContainerType> = ({
  ellipse1,
  dittYrke,
  loremIpsumDolorSitAmetCon,
}) => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.ellipseParent}>
        <img className={styles.frameChild} alt="" src={ellipse1} />
        <b className={styles.dittYrke}>{dittYrke}</b>
      </div>
      <div className={styles.loremIpsumDolor}>{loremIpsumDolorSitAmetCon}</div>
    </div>
  );
};

export default JobAnalysisContainer;
