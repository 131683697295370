import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import IndustryValidationContainer from "../components/IndustryValidationContainer";
import styles from "./Component2.module.css";

const Component2: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/37");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.frameParent}>
        <div className={styles.instanceParent}>
          <div className={styles.dinJobbframtidAnalysisParent}>
            <div className={styles.dinJobbframtidAnalysis}>
              Din Jobbframtid Analysis
            </div>
            <img className={styles.groupIcon} alt="" src="../group4.svg" />
          </div>
          <div className={styles.component76}>
            <div className={styles.frameGroup}>
              <div className={styles.frameChild} />
              <b className={styles.dinFramtid}>
                Din Jobbframtid, en mäjlig resa
              </b>
            </div>
            <div className={styles.viHarNuKommitTillSlutetAWrapper}>
              <b className={styles.viHarNu}>
                Vi har nu kommit till slutet av analysrapporten där vi pratat om
                ditt yrke, olika utvecklingsmöjligheter för din jobbframtid och
                hur du kan stärka din ställning på arbetsmarknaden. Vi hoppas
                att vi har bidragit till kunskaper kring dina
                utvecklingsmöjligheter och hur du kan utveckla din kompetens. På
                den här sidan beskriver vi hur du kan ta dina tankar och gå till
                handling för att förverkliga din jobbframtid. Vi vill
                understryka att det här är till för alla oavsett vilka tidigare
                studier man har bakom sig eller vilka erfarenheter man har av
                skolan sedan tidigare. Vi vill hjälpa alla att utvecklas för att
                stå sig starka på arbetsmarknaden genom att utveckla den
                kompetens som efterfrågas på sikt. I ditt nuvarande yrke eller i
                ett nytt sådant. Kom ihåg att det inte är för sent även om du
                hunnit komma långt i yrkeslivet! Stödet som du kan ta del av kan
                många få mellan det att man är 27 år till dess att man är 62 år
                om man uppfyller vissa villkor. På denna sida kan du läsa om
                villkoren och om hur du går till väga för att ansöka om ett
                vägledningssamtal med en karriär- och studievägledare hos
                Trygghetsfonden TSL.
              </b>
            </div>
          </div>
        </div>
        <div className={styles.ellipseParent}>
          <img className={styles.frameItem} alt="" src="../ellipse-54.svg" />
          <div className={styles.rectangleWrapper}>
            <div className={styles.componentChild} />
          </div>
          <div className={styles.rectangleContainer}>
            <div className={styles.componentItem} />
          </div>
          <div className={styles.rectangleFrame}>
            <div className={styles.componentItem} />
          </div>
          <div className={styles.rectangleDiv}>
            <div className={styles.componentItem} />
          </div>
          <img className={styles.frameInner} alt="" src="../arrow-3.svg" />
          <img className={styles.arrowIcon} alt="" src="../arrow-4.svg" />
          <img className={styles.frameChild1} alt="" src="../arrow-4.svg" />
          <img className={styles.frameChild2} alt="" src="../arrow-3.svg" />
          <img className={styles.frameChild3} alt="" src="../arrow-7.svg" />
          <img className={styles.frameChild4} alt="" src="../arrow-4.svg" />
          <img className={styles.ellipseIcon} alt="" src="../ellipse-52.svg" />
          <img className={styles.frameChild5} alt="" src="../ellipse-53.svg" />
          <img className={styles.lineIcon} alt="" src="../line-6.svg" />
          <img className={styles.frameChild6} alt="" src="../line-4.svg" />
          <img className={styles.frameChild7} alt="" src="../line-5.svg" />
          <div className={styles.dinJobbframtidAnalysWrapper}>
            <b className={styles.dinJobbframtidAnalysContainer}>
              <p className={styles.din}>Din</p>
              <p className={styles.jobbframtidAnalys}>JobbFramtid Analys</p>
            </b>
          </div>
          <div className={styles.component90}>
            <b className={styles.dinJobbframtidAnalysContainer}>
              <p className={styles.din}>TSL</p>
              <p className={styles.jobbframtidAnalys}>Vägledning</p>
            </b>
          </div>
          <div className={styles.component91}>
            <b className={styles.dinJobbframtidAnalys}>?</b>
          </div>
          <IndustryValidationContainer
            courseName="Bransch Validering"
            courseDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam risus augue"
            propLeft="147px"
            propTop="594px"
            propDisplay="none"
            propDisplay1="none"
          />
          <IndustryValidationContainer
            courseName="Yrkeshogskolan"
            courseDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam risus augue, placerat ut ligula nec"
            propLeft="549px"
            propWidth="378px"
            propDisplay="unset"
            propDisplay2="none"
          />
          <div className={styles.component90frame299}>
            <img
              className={styles.component90frame299Child}
              alt=""
              src="../group-2921@2x.png"
            />
            <img
              className={styles.component90frame299Item}
              alt=""
              src="../group-2911@2x.png"
            />
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="../mask-group@2x.png"
            />
            <img
              className={styles.component90frame299Inner}
              alt=""
              src="../group-293@2x.png"
            />
          </div>
          <IndustryValidationContainer
            courseName="Kort utbildry genom TSL"
            courseDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam risus augue, placerat ut ligula nec"
            propLeft="463px"
            propWidth="378px"
            propTop="806px"
            propDisplay1="unset"
            propDisplay2="none"
          />
          <div className={styles.component901}>
            <img className={styles.checkIcon} alt="" src="../check.svg" />
            <b className={styles.nyttJobb}>TSL Vägledning</b>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.dittYrkeWrapper}>
              <b className={styles.dittYrke}>Ditt Yrke</b>
            </div>
            <div className={styles.loremIpsumDolor}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              risus augue, placerat ut ligula nec, sollicitudin gravida mi.
            </div>
          </div>
          <div className={styles.loremIpsumDolorSitAmetCoParent}>
            <div className={styles.loremIpsumDolor1}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              risus augue, placerat ut ligula nec, sollicitudin gravida mi.
            </div>
            <div className={styles.dittYrkeContainer}>
              <b className={styles.dittYrke}>Förändringar</b>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.dittYrkeFrame}>
              <b className={styles.dittYrke}>
                <p className={styles.din}>Ekonomiskt</p>
                <p className={styles.jobbframtidAnalys}>Stöd</p>
              </b>
            </div>
            <div className={styles.loremIpsumDolor2}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              risus augue, placerat ut ligula nec, sollicitudin gravida mi.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.groupParent}>
        <img className={styles.groupIcon} alt="" src="../group1.svg" />
        <div className={styles.dinJobbframtidAnalysis}>Page 14 of 17</div>
      </div>
    </div>
  );
};

export default Component2;
