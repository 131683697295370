import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import JobFramtidContainer from "../components/JobFramtidContainer";
import styles from "./Component4.module.css";

const Component4: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/36");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <img className={styles.child} alt="" src="../group-220.svg" />
      <JobFramtidContainer
        ellipseImageUrl="../ellipse-181.svg"
        careerDevelopmentText="Din utveckling"
        careerDevelopmentText2="Vi hoppas att den analys vi presenterat i föregående avsnitt har skapat insikter och tankar kring din möjliga utveckling. I det här avsnittet vill vi inspirera dig till att tänka på dina utvecklingsmöjligheter för din jobbframtid. Vi tar upp tre olika utvecklingsvägar beroende på hur dina tankar går. I den första delen summerar vi analysen över ditt nuvarande yrke och de kompetenser som efterfrågas som vi hoppas har skapat kunskaper kring vilken kompetensutveckling som krävs över tid. Därefter presenterar vi en analys av hur de kompetenser som efterfrågas i ditt yrke kan användas i närliggande yrken där du kan använda flera av de kompetenser som du redan har. I den sista delen presenterar vi vilka yrken som efterfrågas mest ifall du har eller får tankar på att byta bana i ditt yrkesliv. Efter att du har tagit del av det här avsnittet hoppas vi att du inspirerats till att fördjupa dina tankar kring din utveckling och din jobbframtid."
        propTop="40.7%"
        propHeight="18.6%"
        propBottom="40.7%"
      />
      <img className={styles.svg1Icon} alt="" src="../svg-1.svg" />
      <img className={styles.item} alt="" src="../ellipse-19.svg" />
      <img className={styles.inner} alt="" src="../ellipse-20.svg" />
      <img className={styles.ellipseIcon} alt="" src="../ellipse-21.svg" />
    </div>
  );
};

export default Component4;
