import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DinJobbframtidAnalysisContaine from "../components/DinJobbframtidAnalysisContaine";
import styles from "./FrontCover.module.css";

const FrontCover: FunctionComponent = () => {
  const navigate = useNavigate();

  const onFrontCoverContainerClick = useCallback(() => {
    navigate("/42");
  }, [navigate]);

  return (
    <div className={styles.frontCover} onClick={onFrontCoverContainerClick}>
      <img className={styles.frontCoverChild} alt="" src="../group-2202.svg" />
      <div className={styles.ellipseParent}>
        <img className={styles.groupChild} alt="" src="../ellipse-183.svg" />
        <DinJobbframtidAnalysisContaine />
      </div>
      <img className={styles.frontCoverItem} alt="" src="../ellipse-19.svg" />
      <img className={styles.frontCoverInner} alt="" src="../ellipse-20.svg" />
      <img className={styles.ellipseIcon} alt="" src="../ellipse-21.svg" />
      <img className={styles.svg1Icon} alt="" src="../svg-12.svg" />
    </div>
  );
};

export default FrontCover;
