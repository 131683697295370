import { FunctionComponent } from "react";
import styles from "./ChangeContainer.module.css";

type ChangeContainerType = {
  dinFramtidHurDuKanFrverkl?: string;
};

const ChangeContainer: FunctionComponent<ChangeContainerType> = ({
  dinFramtidHurDuKanFrverkl,
}) => {
  return (
    <div className={styles.component76}>
      <div className={styles.frameParent}>
        <div className={styles.frameChild} />
        <b className={styles.dinFramtid}>{dinFramtidHurDuKanFrverkl}</b>
      </div>
      <div className={styles.viHarNuKommitTillSlutetAWrapper}>
        <b className={styles.viHarNu}>
          Att utveckla sin kompetens så att den motsvarar vad arbetsgivare
          efterfrågar stärker dig på arbetsmarknaden. Här har vi analyserat vad
          arbetsgivare efterfrågar för generella kompetenser kopplade till din
          yrkesroll. Längst till vänster ser du vilka kompetenser som
          efterfrågas i högre utsträckning än tidigare, kompetenser som
          efterfrågas ungefär som tidigare eller kompetenser som efterfrågas
          allt mindre över tid. När du går igenom de olika kompetenserna kan du
          gärna fundera på om du tycker att dessa stämmer med de kompetenser du
          har. Fokusera gärna på de kompetenser som efterfrågas allt mer. Är det
          något du tycker du saknar och skulle vara relevant att utveckla?
        </b>
      </div>
    </div>
  );
};

export default ChangeContainer;
