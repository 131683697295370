import { FunctionComponent, useCallback } from "react";
import JobFramtidContainer from "../components/JobFramtidContainer";
import styles from "./Component3.module.css";

const Component3: FunctionComponent = () => {
  const onContainerClick = useCallback(() => {
    // Please sync "49" to the project
  }, []);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <img className={styles.child} alt="" src="../group-220.svg" />
      <img className={styles.child} alt="" src="../group-220.svg" />
      <JobFramtidContainer
        ellipseImageUrl="../ellipse-18.svg"
        careerDevelopmentText="Din JobbFramtid"
        careerDevelopmentText2="Vi hoppas att den analys vi har presenterat i föregående avsnitt har skapat insikter och tankar kring olika utvecklingsvägar för din jobbframtid. I det här avsnittet vill vi inspirera dig till att förverkliga dina tankar kring din jobbframtid. Detta oavsett om du vill utforska hur du kan vidareutbilda din i det yrke du har, om du vill bredda din kompetens eller om du har tankar om att yrkesväxla. I den fösta delen i detta avsnitt presenterar vi exempel på skolor som kan erbjuda utbildningar (kommentar: denna sida behöver vi prata vidare om). Vilka möjligheter du har för att få hjälp med att prata igenom dina tankar och eventuella utbildningsinsatser med en professionell karriär- och studievägledare kan du läsa om i slutet av detta avsnitt. I det andra avsnittet presenterar vi vilken möjlighet du kan ha till ekonomiskt stöd om du utbildar dig och vilka villkor som gäller för att få ta del av detta. I den sista delen beskriver vi hur du konkret går till väga för att få ta del av det kostnadsfria stödet för att förverkliga din jobbframtid för att stärka din ställning på arbetsmarknaden."
        propTop="calc(50% - 369.5px)"
      />
      <img className={styles.svg1Icon} alt="" src="../svg-1.svg" />
      <img className={styles.inner} alt="" src="../ellipse-19.svg" />
      <img className={styles.ellipseIcon} alt="" src="../ellipse-20.svg" />
      <img className={styles.child1} alt="" src="../ellipse-21.svg" />
    </div>
  );
};

export default Component3;
