import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WorkSkillsContainer from "../components/WorkSkillsContainer";
import SpecificSkillsContainer from "../components/SpecificSkillsContainer";
import GeneralSkillsContainer from "../components/GeneralSkillsContainer";
import styles from "./ComponentSkills.module.css";

type IXSkills = {
  data_skills : any
};

const Component9 = ( props : IXSkills) => {

  const { data_skills } = props;
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/41");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <div className={styles.instanceParent}>
        <WorkSkillsContainer />
        <SpecificSkillsContainer data_skills={data_skills}/>
        <GeneralSkillsContainer data_skills={data_skills}/>
      </div>
    </div>
  );
};

export default Component9;
