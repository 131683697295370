import { FunctionComponent, useCallback } from "react";
import { useEffect, useState } from "react";
import { get_map, get_similarities, get_skills, get_rate_of_change, get_available_occupations, fetchMyAPI } from "./fetch_data";

import "./ComponentInput.css";

type IXProps = {
    set_data: any
};

const ComponentInput = ( props: IXProps ) => {

  const { set_data } = props;

  const onContainerClick = useCallback(() => {
    // Please sync "38" to the project
  }, []);

  const [availOccupations, setAvailOccupations] = useState([{ 'value': 'Lager- och terminalpersonal' }, { 'value': 'Förskollärare' }])

  let test_array = []
  for (let i = 0; i < 18; i++) {
    test_array.push('test' + i.toString())
  }

  const [occupation, setOccupation] = useState('Lager- och terminalpersonal')
  const [geo, setGeo] = useState('SE Riket')
  const [salary, setSalary] = useState('32000')

  const [forandring, setForandring] = useState([100, 200])
  const [names, setNames] = useState(test_array)

  async function update_data() {

    let [data_map, data_similarities, data_skills, data_rate_of_change] =
      await Promise.all([
        get_map(occupation, geo), get_similarities(occupation),
        get_skills(occupation), get_rate_of_change(occupation)])

    setNames(JSON.parse(data_similarities[0]['overlapping_competencies_clean']))
    setForandring([50, 100]);

    props.set_data([data_map, data_similarities, data_skills, data_rate_of_change])
  }

  const pathname = location.pathname;

  useEffect(() => {
    fetchMyAPI().then((result) => { setAvailOccupations(result) });
  }, []); // <-- run once

  function handleChangeOccupation(event: any) {
    setOccupation(event.target.value)
  }

  function handleChangeGeo(event: any) {
    setGeo(event.target.value)
  }

  function handleChangeSalary(event: any) {
    setSalary(event.target.value)
  }

  return (
    <div className="test1">
      <input type="text" list="data" onChange={handleChangeOccupation} />

      <datalist id="data">
        {availOccupations.map((item, key) =>
          <option key={key} value={item.value} />
        )}
      </datalist>

      <input type="text" value={geo} onChange={handleChangeGeo}></input>
      <input type="text" value={salary} onChange={handleChangeSalary}></input>

      <button onClick={update_data}>Update</button>
    </div>
  );
};

export default ComponentInput;