import { FunctionComponent, useCallback } from "react";
import CareerAnalysisContainer from "../components/CareerAnalysisContainer";
import CareerReflectionContainer from "../components/CareerReflectionContainer";
import styles from "./Component13.module.css";

const Component13: FunctionComponent = () => {
  const onContainerClick = useCallback(() => {
    // Please sync "35" to the project
  }, []);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <CareerAnalysisContainer
        dinFramtidHurDuKanFrverkl="Din utveckling: i ditt nuvarande yrke"
        viHarNuKommitTillSlutetAv="På denna sida ser du en summering av de olika analyserna vi har gjort för ditt yrke. Det har handlat om fakta om ditt yrke, hur yrket förändras och vilka kompetenser som efterfrågas. Vi hoppas att analyserna du tagit del av har skapat tankar kring hur du kan vidareutveckla dig i ditt yrke. Vår erfarenhet är att många av oss inte dagligen tänker på hur man kan utvecklas i sitt yrke och inte heller på sin jobbframtid på sikt. Det kan därför vara klokt att prata igenom sina tankar med någon för att planera en utvecklingsplan. Många som genomför den här analysen har via Trygghetsfonden TSL möjlighet att boka in ett eller flera kostnadsfria möten med våra kvalificerade karriär- och studievägledare. I mötet som är kostnadsfritt kan man prata igenom sina tankar och eventuella utbildningsinsatser. Vilka villkor som gäller för att ta del av kostnadsfria karriär- och studievägledarsamtal kan du läsa mer om i slutet av denna rapport."
      />
      <div className={styles.frameParent}>
        <CareerReflectionContainer />
        <div className={styles.groupParent}>
          <img className={styles.groupIcon} alt="" src="../group1.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 10 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component13;
