import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./JobChangeRateContainer.module.css";

type JobChangeRateContainerType = {
  frndringstaktYrke?: string;
  frame324?: string;

  /** Style props */
  propJustifyContent?: Property.JustifyContent;
  propFlexDirection?: Property.FlexDirection;
};

const JobChangeRateContainer: FunctionComponent<JobChangeRateContainerType> = ({
  frndringstaktYrke,
  frame324,
  propJustifyContent,
  propFlexDirection,
}) => {
  const frameDiv3Style: CSS.Properties = useMemo(() => {
    return {
      justifyContent: propJustifyContent,
    };
  }, [propJustifyContent]);

  const frameDiv4Style: CSS.Properties = useMemo(() => {
    return {
      flexDirection: propFlexDirection,
    };
  }, [propFlexDirection]);

  return (
    <div className={styles.frndringstaktYrkeParent} style={frameDiv3Style}>
      <b className={styles.frndringstaktYrke}>{frndringstaktYrke}</b>
      <div className={styles.frameWrapper} style={frameDiv4Style}>
        <img className={styles.frameChild} alt="" src={frame324} />
      </div>
    </div>
  );
};

export default JobChangeRateContainer;
