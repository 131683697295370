import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./ContainerPercentageSlider.module.css";

type ContainerPercentageSliderType = {
  arrowSvgUrl?: string;
  percentageText?: string;
  percentageValue?: string;
  percentageText2?: string;
  percentageText3?: string;

  /** Style props */
  propLeft?: Property.Left;
};

const ContainerPercentageSlider: FunctionComponent<
  ContainerPercentageSliderType
> = ({
  arrowSvgUrl,
  percentageText,
  percentageValue,
  percentageText2,
  percentageText3,
  propLeft,
}) => {
  const efterfrganStyle: CSS.Properties = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  return (
    <div className={styles.vectorParent}>
      <img className={styles.groupChild} alt="" src={arrowSvgUrl} />
      <div className={styles.parent}>
        <div className={styles.div}>{percentageText}</div>
        <div className={styles.div}>{percentageValue}</div>
        <div className={styles.div}>10%</div>
        <div className={styles.div}>{percentageText2}</div>
        <div className={styles.div}>{percentageText3}</div>
      </div>
      <div className={styles.efterfrgan} style={efterfrganStyle}>
        Efterfrägan
      </div>
    </div>
  );
};

export default ContainerPercentageSlider;
