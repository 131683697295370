import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./CareerAnalysisContainer.module.css";

type CareerAnalysisContainerType = {
  dinFramtidHurDuKanFrverkl?: string;
  viHarNuKommitTillSlutetAv?: string;

  /** Style props */
  viHarNuKommitTillSlutetAvBackgroundColor?: Property.BackgroundColor;
};

const CareerAnalysisContainer: FunctionComponent<
  CareerAnalysisContainerType
> = ({
  dinFramtidHurDuKanFrverkl,
  viHarNuKommitTillSlutetAv,
  viHarNuKommitTillSlutetAvBackgroundColor,
}) => {
  const frameDivStyle: CSS.Properties = useMemo(() => {
    return {
      backgroundColor: viHarNuKommitTillSlutetAvBackgroundColor,
    };
  }, [viHarNuKommitTillSlutetAvBackgroundColor]);

  return (
    <div className={styles.component76}>
      <div className={styles.frameParent} style={frameDivStyle}>
        <div className={styles.frameChild} />
        <b className={styles.dinFramtid}>{dinFramtidHurDuKanFrverkl}</b>
      </div>
      <div className={styles.viHarNuKommitTillSlutetAWrapper}>
        <b className={styles.viHarNu}>{viHarNuKommitTillSlutetAv}</b>
      </div>
    </div>
  );
};

export default CareerAnalysisContainer;
