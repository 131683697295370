import { FunctionComponent } from "react";
import styles from "./OccupationChangeRateContainer.module.css";

type IXEfterfragan = {
  data_rate_of_change : any
};

const OccupationChangeRateContainer = ( props : IXEfterfragan ) => {
  
  const { data_rate_of_change } = props;

  let change_value = 3;

  return (
    <div className={styles.frameParent}>
      <div className={styles.frndringstaktYrkeParent}>
        <b className={styles.frndringstaktYrke}>Förändringstakt yrke</b>
        <div
          className={styles.hrSerDu}
        >{`Här ser du hur ditt yrke förändras över tid. Med förändringar menar vi arbetsgivarens efterfrågan på kompetenser i samabnd ,ed förändrade uppgifter inom ditt yrke. `}</div>
      </div>
      <div className={styles.balanceParent}>
        <b className={styles.balance}>Balance</b>
        <b className={styles.lowChange}>
          <p className={styles.low}>Low</p>
          <p className={styles.change}>change</p>
        </b>
        <b className={styles.highChange}>
          <p className={styles.low}>High</p>
          <p className={styles.change}>change</p>
        </b>
        <img className={styles.frameChild} alt=""  src={"../change_" + change_value.toString() + ".svg"} />
      </div>
    </div>
  );
};

export default OccupationChangeRateContainer;
