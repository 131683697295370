import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContainerKompetenser from "../components/ContainerKompetenser";
import styles from "./ComponentKompSpec.module.css";

type ISkills = {
  data_skills : any
};

const ComponentKompSpec = ( props : ISkills ) => {

  const { data_skills } = props;
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/39");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <div className={styles.component76Parent}>
        <div className={styles.component76}>
          <div className={styles.frameParent}>
            <div className={styles.frameChild} />
            <b className={styles.dinFramtid}>
              Ditt yrke: Specifika kompetenser
            </b>
          </div>
          <div className={styles.viHarNuKommitTillSlutetAWrapper}>
            <b className={styles.viHarNu}>
              Att utveckla sin kompetens så att den motsvarar vad arbetsgivare
              efterfrågar stärker dig på arbetsmarknaden. Här har vi analyserat
              vad arbetsgivare efterfrågar för specifika kompetenser och som är
              kopplade till din yrkesroll. Längst till vänster ser du vilka
              kompetenser som efterfrågas i högre utsträckning än tidigare,
              kompetenser som efterfrågas ungefär som tidigare eller kompetenser
              som efterfrågas allt mindre över tid. När du går igenom de olika
              kompetenserna kan du gärna fundera på om du tycker att dessa
              stämmer med de kompetenser du har. Fokusera gärna på de
              kompetenser som efterfrågas allt mer. Är det något du tycker du
              saknar och skulle vara relevant att utveckla?
            </b>
          </div>
        </div>
        <ContainerKompetenser data_skills={data_skills}
          competencyText="Specifika kompetenser"
          pageNumberText="Page 7 of 17"
        />
      </div>
    </div>
  );
};

export default ComponentKompSpec;
