import { FunctionComponent } from "react";
import styles from "./SwedenContainer.module.css";

type IXMap = {
  data_map : any;
};

const SwedenContainer = (props: IXMap) => {

  const { data_map } = props;

  // local
  let local_growth_antal_anstallda = 0;
  let local_ads_nr_2022 = 0;
  let local_growth_ads = 0;
  let local_geo = 0;
  let local_growth_manadslon = 0;

  let country_ads_nr_2022 = 0;
  let country_manadslon = 0;
  let country_growth_manadslon = 0;
  let country_nr_antal_anstallda = 0;
  let country_growth_antal_anstallda = 0;
  let af_bristvarde = 0;
  let af_bristvarde_label = '-';

  if(Object.keys(data_map).length>0) {

    local_growth_antal_anstallda = Math.round(data_map[0]['growth_antal_anställda'] * 100 - 100);

    local_ads_nr_2022 = data_map[0]['ads_nr_2022'];
    local_growth_ads = Math.round(data_map[0]['ads_growth_2022'] * 100 - 100);
    local_geo = data_map[0]['geo'];
    local_growth_manadslon = Math.round(data_map[0]['growth_mandslan'] * 100 - 100);

    // country
    country_ads_nr_2022 = data_map[0]['ads_nr_2022'];
    country_manadslon = data_map[0]['månadslön_2021'];
    country_growth_manadslon = Math.round(data_map[0]['growth_mandslan'] * 100 - 100);
    country_nr_antal_anstallda = data_map[0]['antal_anställda_2021'];
    country_growth_antal_anstallda = Math.round(data_map[0]['growth_antal_anställda'] * 100 - 100);

    let vals = JSON.parse(data_map[0]['af_bristvarde']);
    if(vals!= null && '2022' in vals) {
      af_bristvarde = vals['2022'];
      
      if(af_bristvarde==3)
        af_bristvarde_label = 'små till medelstora möjligheter';
      else if(af_bristvarde==4)
        af_bristvarde_label = 'stora möjligheter';
      else if(af_bristvarde==5)
        af_bristvarde_label = 'mycket stora möjligheter';
    }
  }

  return (
    <div className={styles.frameParent}>
      <div className={styles.stockholmParent}>
        <b className={styles.stockholm}>{ local_geo }</b>
        <div className={styles.parent}>
          <b className={styles.b}>{ local_ads_nr_2022 }</b>
          <div className={styles.jobAdsPtublishedIn}>
            jobbannonser publicerade hittills i år.
          </div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}>{ local_growth_ads }%</b>
          <div className={styles.jobAdsPtublishedIn}>
            är ökning av antal publicerade annonser jämfört med samma perioden
            förra året.
          </div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}>-</b>
          <div
            className={styles.jobAdsPtublishedIn}
          >{`är antal publicerade annonser inom ditt yrke idag. `}</div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}>{ local_growth_manadslon }%</b>
          <div
            className={styles.jobAdsPtublishedIn}
          >{`är snittökning av lönen inom ditt yrke sedan 2017. `}</div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}>{ local_growth_antal_anstallda }%</b>
          <div className={styles.jobAdsPtublishedIn}>
            är ökningen av antal anställda inom ditt yrke och geografiskt område
            sedan 2017.
          </div>
        </div>
      </div>
      <img className={styles.mapaIcon} alt="" src="../mapa1.svg" />
      <div className={styles.sverigeParent}>
        <b className={styles.sverige}>Sverige</b>
        <div className={styles.parent}>
          <b className={styles.b}>{ country_ads_nr_2022 }</b>
          <div className={styles.jobAdsPtublishedIn}>
            jobbannonser publicerade de senaste 12 månader.
          </div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}>{ country_manadslon } kr</b>
          <div className={styles.jobAdsPtublishedIn}>
            är snittlönen inom din region och yrke. Snittlönen har ökat med {country_growth_manadslon}%
            sedan 2017.
          </div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b}> {country_nr_antal_anstallda} </b>
          <div className={styles.jobAdsPtublishedIn}>
            är antal anställda inom ditt yrke i Sverige. Det är en {country_growth_antal_anstallda}% ökning
            sedan 2017.
          </div>
        </div>
        <div className={styles.parent}>
          <b className={styles.b8}>97,900</b>
          <div
            className={styles.jobAdsPtublishedIn}
          >Enligt Arbetsförmedlingen är det <b>{af_bristvarde_label}</b> för att få ett jobb inom yrket det kommande 12 månader.</div>
        </div>
      </div>
    </div>
  );
};

export default SwedenContainer;
