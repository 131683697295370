import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CareerAnalysisContainer from "../components/CareerAnalysisContainer";
import styles from "./Component1.module.css";

const Component1: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/46");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group.svg" />
      </div>
      <CareerAnalysisContainer
        dinFramtidHurDuKanFrverkl="Din utveckling: om du vill byta bana"
        viHarNuKommitTillSlutetAv="Har du tankar på att i framtiden byta yrke helt eller delvis är det viktigt att analysera att de yrken du funderar på är efterfrågade på arbetsmarknaden långsiktigt. I den här delen har vi analyserat olika yrken och i vilken grad de efterfrågas. På sidans vänstra del ser du olika yrken i en lista. Bredvid varje yrke ser du också en pil som anger om efterfrågan på yrket har ökat, om efterfrågan är ungefär lika som tidigare eller om efterfrågan minskar. På sidans högra del har vi angivit en prognos för vilken efterfrågan det kommer att vara på sikt för de olika yrkena(kommentar: här har vi inte en sådan bild i dagsläget. Frågan är om det kan passa här?). De exempel som visas på den här sidan är bara några exempel på yrken som efterfrågas. Vill du få mer kunskap om vad som krävs för att utvecklas mot några av dessa eller andra yrken? Då kan du i den kommande och sista delen av denna rapport läsa mer om vilka möjligheter du har för att få hjälp med att prata igenom dina tankar och eventuella utbildningsinsatser med en professionell karriär- och studievägledare."
        viHarNuKommitTillSlutetAvBackgroundColor="#5f4a8b"
      />
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.frameGroup}>
            <div className={styles.jobsRankingParent}>
              <b className={styles.jobsRanking}>Jobs Ranking</b>
              <div className={styles.frameContainer}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="../group-290.svg"
                  />
                  <div className={styles.dinJobbframtidAnalysis}>Growing</div>
                </div>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="../group-291.svg"
                  />
                  <div className={styles.dinJobbframtidAnalysis}>Stable</div>
                </div>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="../group-292.svg"
                  />
                  <div className={styles.dinJobbframtidAnalysis}>
                    Descending
                  </div>
                </div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.rankingParent}>
                  <b className={styles.ranking}>Ranking</b>
                  <b className={styles.occupation}>Occupation</b>
                  <b className={styles.occupation}>
                    <p className={styles.historical}>Historical</p>
                    <p className={styles.demand}>Demand</p>
                  </b>
                  <b className={styles.occupation}>
                    <p className={styles.historical}>Forecast</p>
                    <p className={styles.demand}>1 - 5 years</p>
                  </b>
                </div>
                <div className={styles.listItems}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>1</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2902.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems1}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>2</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems2}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>3</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2905.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2902.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems3}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>4</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2907.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems4}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>5</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems5}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>6</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Your Job
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems6}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>7</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2905.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-29014.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems7}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>8</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2907.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems8}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>9</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2902.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems9}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>10</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2907.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-29014.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems10}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>11</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-29014.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems11}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>12</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2905.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2902.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems12}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>13</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems13}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>14</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2907.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-29014.svg"
                    />
                  </div>
                </div>
                <div className={styles.listItems14}>
                  <div className={styles.wrapper}>
                    <b className={styles.b}>15</b>
                  </div>
                  <div className={styles.loremIpsumWrapper}>
                    <div className={styles.dinJobbframtidAnalysis}>
                      Job name
                    </div>
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2901.svg"
                    />
                  </div>
                  <div className={styles.listItemsInner}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="../group-2904.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent2}>
              <div className={styles.frameWrapper1}>
                <div className={styles.ellipseParent}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="../ellipse-48.svg"
                  />
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="../ellipse-48.svg"
                  />
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="../ellipse-48.svg"
                  />
                </div>
              </div>
              <div className={styles.listItems15}>
                <div className={styles.wrapper}>
                  <b className={styles.b}>17</b>
                </div>
                <div className={styles.loremIpsumWrapper}>
                  <div className={styles.dinJobbframtidAnalysis}>Your Job</div>
                </div>
                <div className={styles.listItemsInner}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="../group-2901.svg"
                  />
                </div>
                <div className={styles.listItemsInner}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="../group-2904.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.groupGroup}>
          <img className={styles.groupIcon} alt="" src="../group1.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 12 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component1;
