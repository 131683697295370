import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WorkContainer from "../components/WorkContainer";
import TimeDemandContainer from "../components/TimeDemandContainer";
import OccupationChangeRateContainer from "../components/OccupationChangeRateContainer";
import ForecastContainer from "../components/ForecastContainer";
import styles from "./ComponentDemandChange.module.css";

type IXEfterfragan = {
  data_rate_of_change : any
};

const Component8 = ( props: IXEfterfragan ) => {
  
  const { data_rate_of_change } = props;
  
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/38");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <WorkContainer />
      <TimeDemandContainer data_rate_of_change = { data_rate_of_change } />
      <OccupationChangeRateContainer data_rate_of_change = { data_rate_of_change }/>
      <ForecastContainer data_rate_of_change = { data_rate_of_change }/>
    </div>
  );
};

export default Component8;
