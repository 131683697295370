import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ChangeContainer from "../components/ChangeContainer";
import SwedenContainer from "../components/SwedenContainer";
import styles from "./ComponentMap.module.css";

type IMap = {
  data_map : any
};

const Component14 = ( props : IMap) => {

  const { data_map } = props;

  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/40");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group22.svg" />
      </div>
      <ChangeContainer dinFramtidHurDuKanFrverkl="Your work: An Overview" />
      <div className={styles.frameParent}>
        <SwedenContainer data_map={data_map}/>
        <div className={styles.groupParent}>
          <img className={styles.groupIcon} alt="" src="../group1.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 4 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component14;
