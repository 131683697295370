import { FunctionComponent } from "react";
import styles from "./DinJobbframtidAnalysisContaine.module.css";

const DinJobbframtidAnalysisContaine: FunctionComponent = () => {
  return (
    <div className={styles.dinJobbframtidAnalysisParent}>
      <b className={styles.dinJobbframtidAnalysis}>Din Jobbframtid Analysis</b>
      <b className={styles.fullName}>Full Name</b>
      <div className={styles.jobParent}>
        <b className={styles.job}>Job</b>
        <div className={styles.frameChild} />
        <b className={styles.job}>Date</b>
        <div className={styles.frameChild} />
        <b className={styles.job}>City</b>
      </div>
    </div>
  );
};

export default DinJobbframtidAnalysisContaine;
