import { FunctionComponent } from "react";
import styles from "./OccupationContainer.module.css";

const OccupationContainer: FunctionComponent = () => {
  return (
    <div className={styles.groupParent}>
      <img className={styles.frameChild} alt="" src="../group-302.svg" />
      <img className={styles.frameItem} alt="" src="../group-299.svg" />
      <img className={styles.frameInner} alt="" src="../group-300.svg" />
      <div className={styles.instanceParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>English</div>
          </div>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Swedish</div>
          </div>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Driving Licence</div>
          </div>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.instanceChild} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Trustworthy</div>
          </div>
        </div>
        <div className={styles.rectangleParent1}>
          <div className={styles.instanceChild} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Teamplayer</div>
          </div>
        </div>
        <div className={styles.rectangleParent1}>
          <div className={styles.instanceChild2} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent1}>
          <div className={styles.instanceChild2} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent1}>
          <div className={styles.instanceChild2} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent5}>
          <div className={styles.instanceChild2} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent5}>
          <div className={styles.instanceChild6} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent5}>
          <div className={styles.instanceChild6} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
        <div className={styles.rectangleParent5}>
          <div className={styles.instanceChild6} />
          <div className={styles.skillsWrapper}>
            <div className={styles.skills}>Skills</div>
          </div>
        </div>
      </div>
      <div className={styles.yourJobParent}>
        <b className={styles.yourJob}>Your Job</b>
        <b className={styles.sprinklermontr}>Sprinklermontör</b>
      </div>
      <div className={styles.component91Parent}>
        <div className={styles.component91}>
          <div className={styles.component91Child} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Truckdriver</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild9} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>VVS Assembler</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.component91Child} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Ventilation</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.component91Child} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Pipe design</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild9} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild13} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild9} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild9} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild13} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild13} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild9} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
        <div className={styles.component91}>
          <div className={styles.instanceChild13} />
          <div className={styles.relatedJobWrapper}>
            <div className={styles.skills}>Related Job</div>
          </div>
        </div>
      </div>
      <b className={styles.skills12}>Skills</b>
      <b className={styles.occupation}>Occupation</b>
    </div>
  );
};

export default OccupationContainer;
