import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FutureContainer from "../components/FutureContainer";
import JobAnalysisContainer from "../components/JobAnalysisContainer";
import styles from "./Component7.module.css";

const Component7: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/44");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group8.svg" />
      </div>
      <div className={styles.dinJobbframtidAnalysisGroup}>
        <b className={styles.dinJobbframtidAnalysis1}>
          Din Jobbframtid Analysis
        </b>
        <FutureContainer />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.instanceParent}>
          <JobAnalysisContainer
            ellipse1="../ellipse-11.svg"
            dittYrke="Din Jobbframtid Analysis"
            loremIpsumDolorSitAmetCon="Genom att göra Trygghetsfonden TSL:s kompetensanalys ”DinJobbFramtid” kan du upptäcka att det finns många möjligheter att påverka din jobbframtid. I rapporten kan du även läsa om hur du kostnadsfritt kan ta del av Trygghetsfonden TSLs vägledningsstöd för att diskutera och planera din jobbframtid. Rapporten innehåller tre delar."
          />
          <JobAnalysisContainer
            ellipse1="../ellipse-12.svg"
            dittYrke="Ditt Yrke"
            loremIpsumDolorSitAmetCon="I den första delen analyserar vi ditt yrke och beskriver hur efterfrågan av kompetenser inom ditt yrke förändras och hur prognosen för den kortsiktiga och långsiktiga efterfrågan för ditt yrke ser ut. "
          />
          <JobAnalysisContainer
            ellipse1="../ellipse-13.svg"
            dittYrke="Utveckling"
            loremIpsumDolorSitAmetCon="I den andra delen av analysrapporten presenterar vi tre möjliga utvecklingsvägar beroende på hur dina tankar går. Är du nyfiken på hur du kan vidareutveckla dig inom det yrke du har? Vill du bredda din kompetens för att kunna arbeta med yrken där du kan ta vara på det du kan. Eller kanske du har tankar om att helt eller delvis yrkesväxla?   "
          />
          <JobAnalysisContainer
            ellipse1="../ellipse-14.svg"
            dittYrke="Din JobbFramtid"
            loremIpsumDolorSitAmetCon=" Vi hoppas att analysrapporten ska inspirerar dig till att tänka kring din jobbframtid och de möjligheter som Trygghetsfonden TSL erbjuder för att utveckla din kompetens och därigenom även stärka din ställning på arbetsmarknaden."
          />
        </div>
        <div className={styles.groupParent}>
          <img className={styles.groupIcon} alt="" src="../group9.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 2 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component7;
