import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Component from "./pages/Component";
import Component1 from "./pages/Component1";
import Component2 from "./pages/Component2";
import Component3 from "./pages/Component3";
import Component4 from "./pages/Component4";
import Component5 from "./pages/Component5";
import FrontCover from "./pages/FrontCover";
import Component6 from "./pages/Component6";
import Component7 from "./pages/Component7";
import ComponentKompGen from "./pages/ComponentKompGen";
import ComponentKompSpec from "./pages/ComponentKompSpec";
import Component12 from "./pages/Component12";
import Component13 from "./pages/Component13";
import ComponentMap from "./pages/ComponentMap";

import ComponentInput from "./general/ComponentInput";
import ComponentDemandChange from "./pages/ComponentDemandChange";
import ComponentSkills from "./pages/ComponentSkills";

import { useEffect, useState } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const [data_map, set_data_map] = useState({});
  const [data_skills, set_skills] = useState({});
  const [data_rate_of_change, set_rate_of_change] = useState({});

  let data = null;
  const set_data = (d: any) => {
    const [data_map, data_similarities, data_skills, data_rate_of_change] = d;
    set_data_map(data_map);
    set_skills(data_skills);
    set_rate_of_change(data_rate_of_change);
  };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);


  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/48":
        title = "";
        metaDescription = "";
        break;
      case "/47":
        title = "";
        metaDescription = "";
        break;
      case "/46":
        title = "";
        metaDescription = "";
        break;
      case "/45":
        title = "";
        metaDescription = "";
        break;
      case "/44":
        title = "";
        metaDescription = "";
        break;
      case "/front-cover":
        title = "";
        metaDescription = "";
        break;
      case "/43":
        title = "";
        metaDescription = "";
        break;
      case "/42":
        title = "";
        metaDescription = "";
        break;
      case "/41":
        title = "";
        metaDescription = "";
        break;
      case "/40":
        title = "";
        metaDescription = "";
        break;
      case "/39":
        title = "";
        metaDescription = "";
        break;
      case "/38":
        title = "";
        metaDescription = "";
        break;
      case "/37":
        title = "";
        metaDescription = "";
        break;
      case "/36":
        title = "";
        metaDescription = "";
        break;
      case "/34":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <div>

  <ComponentInput set_data = { set_data }/>

      <FrontCover />
      <Component7 />

      Map:
      <ComponentMap data_map = { data_map } />

      Överblick:
      <ComponentSkills data_skills={ data_skills } />

      Times are changing:
      <ComponentDemandChange data_rate_of_change={ data_rate_of_change } />

      Specific:
      <ComponentKompSpec data_skills={data_skills}/>

      General:
      <ComponentKompGen data_skills={data_skills}/>

      Similarities:
      <Component />
      
      Jobs ranking:
      <Component1 />

      Summary:
      <Component13 />

      END


      <Component2 />
      <Component3 />
      <Component4 />
      <Component5 />
      
      <Component12 />
      <Component6 />
      
      
      
    </div>
  );
}
export default App;

/*<Routes>
      
      <Route path="/" element={<Component />} />

      <Route path="/48" element={<Component1 />} />

      <Route path="/47" element={<Component2 />} />

      <Route path="/46" element={<Component3 />} />

      <Route path="/45" element={<Component4 />} />

      <Route path="/44" element={<Component5 />} />

      <Route path="/front-cover" element={<FrontCover />} />

      <Route path="/43" element={<Component6 />} />

      <Route path="/42" element={<Component7 />} />

      <Route path="/41" element={<Component8 />} />

      <Route path="/40" element={<Component9 />} />

      <Route path="/39" element={<Component10 />} />

      <Route path="/38" element={<Component11 />} />

      <Route path="/37" element={<Component12 />} />

      <Route path="/36" element={<Component13 />} />

      <Route path="/34" element={<Component14 />} />
    </Routes>*/
