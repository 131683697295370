import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CareerAnalysisContainer from "../components/CareerAnalysisContainer";
import styles from "./Component12.module.css";

const Component12: FunctionComponent = () => {
  const navigate = useNavigate();

  const onContainerClick = useCallback(() => {
    navigate("/43");
  }, [navigate]);

  return (
    <div className={styles.div} onClick={onContainerClick}>
      <div className={styles.dinJobbframtidAnalysisParent}>
        <div className={styles.dinJobbframtidAnalysis}>
          Din Jobbframtid Analysis
        </div>
        <img className={styles.groupIcon} alt="" src="../group4.svg" />
      </div>
      <CareerAnalysisContainer
        dinFramtidHurDuKanFrverkl="Din JobbFramtid: ekonomiskt stöd"
        viHarNuKommitTillSlutetAv="För många har det inte funnits möjligheter att tänka på studier mitt i yrkeslivet eftersom det svårt att klara sin försörjning. Glädjande finns nu för många möjligheten att få ett ekonomiskt stöd när man studerar. Om du har fyllt i en lön i början av denna rapport kan du se ungefär vad det skulle betyda för dig. Det finns olika villkor som man behöver uppfylla och för att få exakta belopp behöver du ansöka om vägledningsstöd via Trygghetsfonden TSL. Längst ner på sidan kan du få en kort förklaring vad de olika ekonomiska stöden kommer ifrån och vad man behöver eller inte behöver betala tillbaka.   "
        viHarNuKommitTillSlutetAvBackgroundColor="#304f40"
      />
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.krWrapper}>
              <b className={styles.kr}>3 510 kr</b>
            </div>
            <div className={styles.thisIsTheMontlyAmountYouWrapper}>
              <b className={styles.thisIsThe}>
                This is the montly amount you could be entitled to should you
                study fulltime
              </b>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <b className={styles.ekonomisktStdFrContainer}>
              <p className={styles.ekonomisktStdFr}>
                Ekonomiskt stöd för utbildningar
              </p>
              <p className={styles.dagarEllerMer}>5 dagar eller mer</p>
            </b>
            <div className={styles.frameDiv}>
              <div className={styles.perMnadParent}>
                <b className={styles.perMnad}>Per månad</b>
                <div className={styles.groupParent}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.groupChild} />
                    <div className={styles.omstllningsstudiebidragFrnParent}>
                      <div className={styles.dinJobbframtidAnalysis}>
                        Omställningsstudiebidrag från CSN
                      </div>
                      <b className={styles.b}>750</b>
                    </div>
                  </div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.groupItem} />
                    <div className={styles.omstllningsstudiebidragFrnParent}>
                      <div className={styles.dinJobbframtidAnalysis}>
                        Kompletterande kollektivavtalat studiestöd från TSL
                      </div>
                      <b className={styles.b}>600</b>
                    </div>
                  </div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.groupInner} />
                    <div className={styles.omstllningsstudiebidragFrnParent}>
                      <div className={styles.dinJobbframtidAnalysis}>
                        Omställningsstudielån från CSN
                      </div>
                      <b className={styles.b}>500</b>
                    </div>
                  </div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.rectangleDiv} />
                    <div className={styles.omstllningsstudiebidragFrnParent}>
                      <div className={styles.dinJobbframtidAnalysis}>
                        Din eventuella lön beroende på studieomfattning
                      </div>
                      <b className={styles.b}>200</b>
                    </div>
                  </div>
                </div>
                <div className={styles.totalParent}>
                  <div className={styles.dinJobbframtidAnalysis}>Total</div>
                  <b className={styles.b4}>3510</b>
                </div>
              </div>
              <div className={styles.perDagParent}>
                <b className={styles.perMnad}>Per dag</b>
                <div className={styles.groupWrapper}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.groupChild1} />
                    <div className={styles.omstllningsstudiebidragFrnParent2}>
                      <div className={styles.dinJobbframtidAnalysis}>
                        Omställningsstudiebidrag från CSN
                      </div>
                      <b className={styles.b}>25</b>
                    </div>
                  </div>
                </div>
                <div className={styles.totalParent}>
                  <div className={styles.dinJobbframtidAnalysis}>Total</div>
                  <b className={styles.b4}>25</b>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.braAttVetaParent}>
            <b className={styles.braAttVeta}>Bra att veta</b>
            <div className={styles.frameDiv}>
              <div className={styles.viInformerarDigContainer}>
                <p className={styles.ekonomisktStdFr}>
                  Vi informerar dig om dina möjligheter till ekonomiskt stöd
                  under utbildning i samband med vårt vägledningssamtal med dig.
                </p>
                <p className={styles.ekonomisktStdFr}>&nbsp;</p>
                <p className={styles.dagarEllerMer}>
                  Beloppen för Omställningsstudiebidraget från CSN och det
                  kompletterande kollektivavtalade studiestödet från TSL är
                  angivna i bruttobelopp, vilket betyder att du betalar skatt på
                  beloppen. Omställningsstudielånet är skattefritt, vilket
                  betyder att beloppet för lånet är netto. Samtliga belopp är
                  avrundade
                </p>
              </div>
              <div className={styles.viInformerarDigContainer}>
                Om du vill gå en utbildning som är kortare än fem dagar kan du i
                vissa fall ha rätt till ett kortvarigt kollektivavtalet
                studiestöd under dina utbildningsdagar då du inte har lön. Vi på
                Trygghetsfonden TSL tar beslut om detta stöd och betalar ut
                pengarna.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.groupGroup}>
          <img className={styles.groupIcon} alt="" src="../group1.svg" />
          <div className={styles.dinJobbframtidAnalysis}>Page 16 of 17</div>
        </div>
      </div>
    </div>
  );
};

export default Component12;
