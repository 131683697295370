import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./JobFramtidContainer.module.css";

type JobFramtidContainerType = {
  ellipseImageUrl?: string;
  careerDevelopmentText?: string;
  careerDevelopmentText2?: string;

  /** Style props */
  propTop?: Property.Top;
  propHeight?: Property.Height;
  propBottom?: Property.Bottom;
};

const JobFramtidContainer: FunctionComponent<JobFramtidContainerType> = ({
  ellipseImageUrl,
  careerDevelopmentText,
  careerDevelopmentText2,
  propTop,
  propHeight,
  propBottom,
}) => {
  const frameDiv2Style: CSS.Properties = useMemo(() => {
    return {
      top: propTop,
      height: propHeight,
      bottom: propBottom,
    };
  }, [propTop, propHeight, propBottom]);

  return (
    <div className={styles.ellipseParent}>
      <img className={styles.groupChild} alt="" src={ellipseImageUrl} />
      <div className={styles.dinJobbframtidParent} style={frameDiv2Style}>
        <b className={styles.dinJobbframtid}>{careerDevelopmentText}</b>
        <div className={styles.viHoppasAtt}>{careerDevelopmentText2}</div>
      </div>
    </div>
  );
};

export default JobFramtidContainer;
